import "core-js/modules/es.promise";
import whenDomReady from "when-dom-ready";
import siteConfig from "../modules/siteConfig";
import extend from "../modules/extend";
import video from "../modules/video";
import nav from "../modules/nav";
import analyticsEvent from "../modules/analyticsEvent";

import "../../css/base.scss";

class BasePage {
    constructor(...opts) {
        extend(this, opts);
        this.init();
    }

    init() {
        var self = this;
        self.ready = false;
        self.onBeforeReady();

        whenDomReady().then(function() {
            self.onReady();
        });

        window.addEventListener("load", function() {
            self.onLoad();
        });
    }

    onBeforeReady() {
        extend(this, siteConfig);
    }

    onReady() {
        var self = this;
        self.ready = true;
        nav.showNav();
        video.init();
        analyticsEvent();
    }

    onLoad() {}

    isReady() {
        var self = this;
        return self.ready === true;
    }

    noop() {}
}

export default BasePage;
