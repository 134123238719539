import BasePage from "./base";
import "../../css/pages/home.scss";
import slideshow from "../modules/slideshow";
import iconSlideshow from "../modules/iconSlideshow";
import mgnlForm from "../modules/mgnlForm";

class HomePage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
        mgnlForm.init();
    }

    onLoad() {
        slideshow.slide();
        iconSlideshow.slide();
    }
}

new HomePage();
