const gallery = document.querySelectorAll(".jsGallery");

const func = {
    createModal(item, key) {
        const max = item.length;
        let modal = document.createElement("DIV");
        modal.classList.add("gallery__modal");
        modal.setAttribute("id", `gallery-modal-${key}`);

        let modalTemp = `
            <div class="gallery__modalOverlay"></div>
            <div class="gallery__modalMain">
                <div class="gallery__modalContent">
                    <div class="gallery__modalClose">
                        <span class="gallery__modalCloseIcon">×</span>
                    </div>
                    <figure>
                        <img src="#" class="gallery__enlarge">
                    </figure>
                    <div class="gallery__modalDetail">
                        <div class="gallery__modalTitle"></div>
                        <div class="gallery__modalCounter"><span>1</span> of ${max}</div>
                    </div>
                </div>
            </div>
            <div class="gallery__modalIcon gallery__modalIcon--prev"></div>
            <div class="gallery__modalIcon gallery__modalIcon--next"></div>
        `;

        modal.innerHTML = modalTemp;

        if (max === 1) {
            modal.querySelector(".gallery__modalCounter").style.display = "none";
            modal.querySelector(".gallery__modalIcon--prev").style.display = "none";
            modal.querySelector(".gallery__modalIcon--next").style.display = "none";
        }

        document.body.appendChild(modal);
    },
    itemHover(item) {
        item.forEach(gItem => {
            const overlay = gItem.querySelector(".gallery__overlay");

            gItem.onmouseover = () => {
                overlay.style.opacity = 1;
                overlay.classList.add("gallery__overlay--show");
            };

            gItem.onmouseleave = () => {
                overlay.style.opacity = 0;
                overlay.classList.remove("gallery__overlay--show");
            };
        });
    },
    enlarge(item, key) {
        const modal = document.querySelector(`#gallery-modal-${key}`);
        const enlarge = modal.querySelector(".gallery__enlarge");
        const modalOverlay = modal.querySelector(".gallery__modalOverlay");
        const modalClose = modal.querySelector(".gallery__modalCloseIcon");

        item.forEach((gItem, gKey) => {
            const img = gItem.querySelector(".gallery__img");
            const label = gItem.querySelector(".gallery__label");
            const labelText = label ? label.textContent : null;
            const src = img ? img.src : null;

            gItem.onclick = () => {
                modal.classList.add("gallery__modal--show");
                enlarge.setAttribute("src", src);
                enlarge.setAttribute("alt", labelText);
                modal.querySelector(".gallery__modalCounter span").textContent = gKey + 1;
                modal.querySelector(".gallery__modalTitle").textContent = labelText;
                document.body.style.overflow = "hidden";

                setTimeout(() => {
                    modalOverlay.style.opacity = 1;
                    enlarge.style.opacity = 1;
                    enlarge.style.transform = "scale(1)";
                }, 100);

                func.icon(item, modal, gKey);
            };
        });

        modalOverlay.onclick = () => {
            modalOverlay.style.opacity = 0;
            enlarge.style.opacity = 0;
            enlarge.style.transform = "scale(0.5)";

            setTimeout(() => {
                document.body.style.overflow = "auto";
                modal.classList.remove("gallery__modal--show");
            }, 300);
        };

        modalClose.onclick = () => {
            modalOverlay.style.opacity = 0;
            enlarge.style.opacity = 0;
            enlarge.style.transform = "scale(0.5)";

            setTimeout(() => {
                document.body.style.overflow = "auto";
                modal.classList.remove("gallery__modal--show");
            }, 300);
        };
    },
    icon(item, modal, key) {
        const max = item.length;
        const next = modal.querySelector(".gallery__modalIcon--next");
        const prev = modal.querySelector(".gallery__modalIcon--prev");
        const enlarge = modal.querySelector(".gallery__enlarge");
        let count = key;

        const change = count => {
            const src = item[count].querySelector(".gallery__img").src;
            const label = item[count].querySelector(".gallery__label");
            const labelText = label ? label.textContent : null;

            enlarge.setAttribute("src", src);

            modal.querySelector(".gallery__modalCounter span").textContent = count + 1;
            modal.querySelector(".gallery__modalTitle").textContent = labelText;
        };

        enlarge.onclick = () => {
            count += 1;
            if (count === max) count = 0;

            change(count);
        };

        next.onclick = () => {
            count += 1;
            if (count === max) count = 0;

            change(count);
        };

        prev.onclick = () => {
            count -= 1;
            if (count === -1) count = max - 1;

            change(count);
        };
    },
    init() {
        gallery.forEach((el, key) => {
            func.createModal(el.querySelectorAll(".gallery__item"), key);
            func.itemHover(el.querySelectorAll(".gallery__item"));
            func.enlarge(el.querySelectorAll(".gallery__item"), key);
        });
    }
};

export default func;
