/**
 * Deep extends an object with any number of other objects. Overrides duplicate
 * properties in the `out` object.
 *
 * Adapted from http://youmightnotneedjquery.com/
 *
 * @param  {Object} out       The object to be extended
 * @param  {Object} arguments Other objects to be merged into `out`
 * @return {Object}           The extended object
 */
export default function extend(out) {
    out = out || {};

    for (var i = 1; i < arguments.length; i++) {
        var obj = arguments[i];

        if (!obj) continue;

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === "object") out[key] = extend(out[key], obj[key]);
                else out[key] = obj[key];
            }
        }
    }

    return out;
}
