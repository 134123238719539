function init() {
    let forms = document.querySelectorAll(".form");

    if (forms.length > 0) {
        appendScript("https://www.google.com/recaptcha/api.js", function() {
            let scanDetailPage = document.querySelector(".scanDetail");
            let contactUsComponent = document.querySelector(".contactUs");

            for (let i = 0; i < forms.length; i++) {
                let form = forms[i].querySelector("form");

                if (form) {
                    let buttonWrapper = form.querySelector(".button-wrapper");

                    if (contactUsComponent && contactUsComponent.contains(form)) {
                        addPlaceholderText(form);
                    } else {
                        if (scanDetailPage && scanDetailPage.contains(form)) {
                            watchRowWidth(form, 442);
                        } else {
                            watchRowWidth(form);
                        }
                    }

                    setupSubmitListeners(form, buttonWrapper);
                }
            }
        });
    }
}

function watchRowWidth(form, minVal = 375, midVal = 1351, maxVal = null) {
    if (form) {
        let stylesheet = document.createElement("style");
        document.querySelector("head").appendChild(stylesheet);

        // calculate initial form input width
        adjustRowWidth(form, minVal, midVal, maxVal, stylesheet);

        // event listener on window resize, updating form input width
        window.addEventListener("resize", function() {
            adjustRowWidth(form, minVal, midVal, maxVal, stylesheet);
        });
    }
}

function addPlaceholderText(form) {
    form.querySelectorAll("input[type='text']").forEach(inpt => {
        if (inpt.placeholder === "") {
            let labelId = form.querySelector(`label[for="${inpt.id}"]`);
            if (labelId) inpt.placeholder = labelId.textContent.trim();
        }
    });
}

function setupSubmitListeners(form, buttonWrapper = form.querySelector(".button-wrapper")) {
    let submitBtn = buttonWrapper.querySelector("input");

    // on submit
    submitBtn.addEventListener("click", function() {
        buttonWrapper.classList.add("button-wrapper--loading");
    });

    // listen for invalid events
    form.addEventListener(
        "invalid",
        function() {
            buttonWrapper.classList.remove("button-wrapper--loading");
        },
        true
    );
}

function adjustRowWidth(form, minVal, midVal, maxVal, stylesheet) {
    let innerWidth = window.innerWidth;

    if (innerWidth < minVal) {
        stylesheet.innerHTML = `.form-row { width: ${form.clientWidth}px; }`;
    } else if (innerWidth < midVal) {
        stylesheet.innerHTML = "";
    } else if (maxVal && innerWidth < maxVal) {
        stylesheet.innerHTML = `.form-row { width: ${form.clientWidth}px; }`;
    }
}

function appendScript(src, onload = () => {}) {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.onload = function() {
        script.onload = null;
        onload();
    };
    document.getElementsByTagName("head")[0].appendChild(script);
    script.src = src;
}

export default { init };
