export default function(selector) {
    const configElements = document.querySelectorAll(selector);
    let config = {};

    if (configElements) {
        try {
            Array.prototype.forEach.call(configElements, function(el) {
                Object.assign(config, JSON.parse(el.innerHTML.trim()));
            });
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
        }
    }

    return config;
}
