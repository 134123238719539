const slideshow = document.querySelectorAll(".iconSlideshow");
const itemShow = window.innerWidth < 768 ? 1 : 4;
let autoInterval;

const func = {
    listHover(list) {
        const icon = list.querySelectorAll(".iconSlideshow__icon");
        list.onmouseover = function() {
            icon.forEach(el => {
                el.classList.add("iconSlideshow__icon--show");
            });
        };

        list.onmouseleave = function() {
            icon.forEach(el => {
                el.classList.remove("iconSlideshow__icon--show");
            });
        };
    },
    autoPlay(el, list, itemArr, item) {
        clearInterval(autoInterval);

        autoInterval = setInterval(() => {
            const last = itemArr.pop();
            const max = item.length;
            let limit = last + itemShow;
            let counter = last;
            let itemTemp = "";
            let arr = [];

            for (let count = last + 1; count <= limit; count += 1) {
                if (count === max) counter = -1;

                if (counter < max) counter += 1;
                else counter = count;

                arr.push(counter);
            }

            arr.forEach(val => {
                itemTemp += item[val].outerHTML;
            });

            el.querySelector(".iconSlideshow__holder").innerHTML = itemTemp;
            func.autoPlay(el, list, arr, item);
            func.next(el, list, arr, item);
            func.prev(el, list, arr, item);
        }, 10e3);
    },
    next(el, list, itemArr, item) {
        const icon = list.querySelector(".iconSlideshow__icon--next");

        icon.onclick = function() {
            const last = itemArr.pop();
            const max = item.length;
            let limit = last + itemShow;
            let counter = last;
            let itemTemp = "";
            let arr = [];

            for (let count = last + 1; count <= limit; count += 1) {
                if (count === max) counter = -1;

                if (counter < max) counter += 1;
                else counter = count;

                arr.push(counter);
            }

            arr.forEach(val => {
                itemTemp += item[val].outerHTML;
            });

            el.querySelector(".iconSlideshow__holder").innerHTML = itemTemp;
            func.autoPlay(el, list, arr, item);
            func.next(el, list, arr, item);
            func.prev(el, list, arr, item);
        };
    },
    prev(el, list, itemArr, item) {
        const icon = list.querySelector(".iconSlideshow__icon--prev");

        icon.onclick = function() {
            const start = itemArr[0];
            const max = item.length;
            let limit = start - itemShow;
            let counter = start;
            let itemTemp = "";
            let arr = [];

            for (let count = start - 1; count >= limit; count -= 1) {
                if (count === -1) counter = max;

                if (counter > 0) counter -= 1;
                else counter = count;

                arr.push(counter);
            }

            arr.reverse().forEach(val => {
                itemTemp += item[val].outerHTML;
            });

            el.querySelector(".iconSlideshow__holder").innerHTML = itemTemp;
            func.autoPlay(el, list, arr, item);
            func.next(el, list, arr, item);
            func.prev(el, list, arr, item);
        };
    },
    slide() {
        slideshow.forEach(el => {
            const list = el.querySelector(".jsIconSlideshow");

            if (!list) {
                const list = el.querySelector(".iconSlideshow__list");
                const loading = el.querySelector(".iconSlideshow__loading");

                list.style.display = "block";
                loading.style.display = "none";
                return false;
            }

            const loading = el.querySelector(".iconSlideshow__loading");
            const holder = list.querySelector(".iconSlideshow__holder");
            const item = holder.querySelectorAll(".iconSlideshow__item");
            let itemTemp = "";
            let itemArr = window.innerWidth < 768 ? [0] : [0, 1, 2, 3];

            if (item.length !== 0) {
                itemArr.forEach(val => {
                    if (item[val]) itemTemp += item[val].outerHTML;
                });

                el.querySelector(".iconSlideshow__holder").innerHTML = itemTemp;

                if (item.length > 4) {
                    func.autoPlay(el, list, itemArr, item);
                    func.next(el, list, itemArr, item);
                    func.prev(el, list, itemArr, item);
                } else {
                    list.querySelectorAll(".iconSlideshow__icon").forEach(icon => {
                        icon.style.display = "none";
                    });
                }
            } else {
                list.querySelectorAll(".iconSlideshow__icon").forEach(icon => {
                    icon.style.display = "none";
                });

                list.innerHTML = `
                    <div class="iconSlideshow__noItem">
                        No Slide Item
                    </div>
                `;
            }

            list.style.display = "block";
            loading.style.display = "none";

            func.listHover(list);
        });
    }
};

export default func;
