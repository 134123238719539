const pagename = document.querySelector("title").textContent;

const linkEvent = () => {
    const links = document.querySelectorAll("a");

    links.forEach(el => {
        const label = el.dataset.label;
        const event = el.dataset.event;
        const url = el.getAttribute("href");

        if (!label && !event) return false;

        el.addEventListener("click", () => {
            const data = {
                event: event,
                page: {
                    category: pagename,
                    label: `${label}`,
                    path: url
                }
            };
            window.dataLayer.push(data);
        });
    });
};

const submitEvent = () => {
    const form = document.querySelectorAll("form");

    form.forEach(el => {
        el.addEventListener("submit", () => {
            const path = location.pathname;
            const pageName = document.querySelector("title").textContent;
            const formId = el.getAttribute("id") || path; /* following eventual Pardot transition, change to el.getAttribute("action") */

            const data = {
                event: "custom-form-submission" /* following eventual Pardot transition, change to "form-submission" */,
                formId: formId,
                page: {
                    category: "Form Event",
                    label: `${pageName} | ${path}`
                }
            };
            window.dataLayer.push(data);
        });
    });
};

const init = () => {
    linkEvent();
    submitEvent();
};

export default init;
