let vidyardScriptLoaded = false;
let apiLoaded = false;

const getVideoId = (element, idAttribute) => element.getAttribute(idAttribute) || "";

const videoIsInline = element => element.classList.contains("video--inlineEmbed");

const appendOverlay = videoType => {
    var loaderOverlay = document.createElement("div");
    loaderOverlay.style.cssText = "background-image: rgba(0,0,0,.58) linear-gradient(90deg,rgba(7,35,62,.9),rgba(0,48,85,.9)) repeat scroll 0 0!important; height: 100vh; left: 0px; opacity: 1; position: fixed; top: 0px; width: 100vw; z-index: 1000; background-color: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center;";
    loaderOverlay.className = "popover__overlay popover__overlay--" + videoType;
    loaderOverlay.innerHTML = '<div class="spinner"></div>';
    document.getElementsByTagName("body")[0].appendChild(loaderOverlay);
};

const loadVidyardScript = (cb = () => {}, ...args) => {
    appendScript("https://play.vidyard.com/embed/v4.js", function() {
        vidyardScriptLoaded = true;
        cb.apply(this, args);
    });
};

const awaitVidyardAPI = (cb = () => {}, ...args) => {
    var interval = window.setInterval(checkStatus, 100);

    function checkStatus() {
        if (window.VidyardV4) {
            clearInterval(interval);
            apiLoaded = true;
            cb.apply(this, args);
        }
    }
};

const prepareInline = (videoId, videoCover) => {
    // eslint-disable-next-line
    window.VidyardV4.api.addReadyListener((_, player) => {
        player.on("play", function() {
            if (videoCover.classList) videoCover.classList.add("video__videoCover--hidden");
            player._callbackStore.play = []; /* runs first time only */
        });
    }, videoId);
};

const playLightbox = videoId => {
    var player = window.VidyardV4.api.getPlayersByUUID(videoId)[0];
    if (player) {
        player.showLightbox();
        window.VidyardV4.api.addReadyListener(() => {
            let overlay = document.querySelectorAll(".popover__overlay--vidyard")[0];
            if (overlay) document.getElementsByTagName("body")[0].removeChild(overlay);
        }, videoId);
    } else {
        // keep trying until this specific player loads
        window.setTimeout(playLightbox, 100, videoId);
    }
};

function vidyard() {
    let vidyardVideos = document.querySelectorAll(".video--vidyard");

    if (vidyardVideos.length > 0) {
        vidyardVideos.forEach(function(element) {
            setBackground(element);
            let videoEmbed = element.querySelector(".video__embed") || "";
            let videoId = getVideoId(videoEmbed, "data-uuid");

            if (videoIsInline(element)) {
                if (!vidyardScriptLoaded) loadVidyardScript();
                let videoCover = element.querySelector(".video__videoCover") || "";
                apiLoaded ? prepareInline(videoId, videoCover) : awaitVidyardAPI(prepareInline, videoId, videoCover);
            } else {
                element.addEventListener("click", function() {
                    appendOverlay("vidyard");
                    if (!vidyardScriptLoaded) loadVidyardScript();
                    apiLoaded ? playLightbox(videoId) : awaitVidyardAPI(playLightbox, videoId);
                });
            }
        });
    }
}

function youtube() {
    let youtubeVideos = document.querySelectorAll(".video--youtube");
    if (youtubeVideos.length > 0) {
        youtubeVideos.forEach(function(element) {
            let videoId = element.getAttribute("data-videoId");
            let videoContainer = element.querySelectorAll(".video__container");
            let videoPlayButton = element.querySelectorAll(".video__play");
            let videoUrl = "//www.youtube.com/embed/" + videoId + "?autoplay=1";
            videoPlayButton[0].addEventListener("click", function() {
                videoContainer[0].innerHTML = "<iframe src='" + videoUrl + "' frameborder='0' allowfullscreen='1' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'></iframe>";
            });
        });
    }
}

function setBackground(elememt) {
    let coverSrc;
    let dataImg;
    let coverImageEl = elememt.querySelector(".videoCover__img");
    if (coverImageEl) dataImg = coverImageEl.getAttribute("data-img");
    if (dataImg == "false") {
        let videoEmbed = elememt.querySelector(".video__embed");
        if (videoEmbed) coverSrc = videoEmbed.getAttribute("src");
        let bgStyle = "background-image: url('" + coverSrc + "')";
        coverImageEl.setAttribute("style", bgStyle);
    }
}

function appendScript(src, onload = () => {}) {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.onload = function() {
        script.onload = null;
        onload();
    };
    document.getElementsByTagName("head")[0].appendChild(script);
    script.src = src;
}

function init() {
    vidyard();
    youtube();
}

export default { init };
