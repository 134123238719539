const func = {
    showNav() {
        const menu = document.querySelector(".et_mobile_menu");
        const bar = document.querySelector(".mobile_menu_bar_toggle");
        let show = false;

        bar.onclick = function() {
            show = !show;

            if (show) {
                menu.style.display = "block";

                setTimeout(() => {
                    menu.style.opacity = 1;
                    menu.style.marginTop = 0;
                }, 10);
            } else {
                menu.style.opacity = 0;
                menu.style.marginTop = "-30px";

                setTimeout(() => {
                    menu.style.display = "none";
                }, 310);
            }
        };
    }
};

export default func;
