const slideshow = document.querySelectorAll(".slideshow");
let count = 0;

const func = {
    control(controls, control, item) {
        control.forEach((el, key) => {
            el.onclick = function() {
                count = key;

                control.forEach(cItem => {
                    cItem.classList.remove("slideshow__controlsItem--active");
                });

                this.classList.add("slideshow__controlsItem--active");
                item[key].classList.add("slideshow__item--active");

                if (Array.prototype.slice.call(item[key].classList).indexOf("slideshow__item--dark") !== -1) controls.classList.add("slideshow__controls--dark");
                else controls.classList.remove("slideshow__controls--dark");

                setTimeout(() => {
                    item.forEach((sItem, key) => {
                        if (key !== count) sItem.classList.remove("slideshow__item--active");
                    });
                }, 500);
            };
        });
    },
    slide() {
        slideshow.forEach(el => {
            let list = el.querySelector(".jsSlide");
            let loading = el.querySelector(".slideshow__loading");

            loading.style.display = "none";

            if (!list) {
                const list = el.querySelector(".slideshow__list");
                let item = list.querySelectorAll(".slideshow__item");

                list.style.display = "block";
                item.forEach(val => {
                    val.querySelector(".slideshow__container").style.position = "absolute";
                    val.querySelector(".slideshow__container").style.top = "0";
                    val.style.display = "block";
                    val.style.position = "relative";
                    val.style.minHeight = "430px";
                });

                return false;
            }

            let item = list.querySelectorAll(".slideshow__item");
            let controls = el.querySelector(".slideshow__controls");
            let max = item.length - 1;
            const listTemplate = list.innerHTML;

            let controlTemp = "";

            if (item.length === 0) {
                list.innerHTML = `
                    <div class="slideshow__noItem">
                        No Slide Item
                    </div>
                `;

                return false;
            }

            item[count].classList.add("slideshow__item--active");

            if (item.length === 1) return false;

            item.forEach(() => {
                controlTemp += `<span class="slideshow__controlsItem"></span>`;
            });

            controls.innerHTML = controlTemp;
            let controlsItem = el.querySelectorAll(".slideshow__controlsItem");
            controlsItem[count].classList.add("slideshow__controlsItem--active");

            if (Array.prototype.slice.call(item[count].classList).indexOf("slideshow__item--dark") !== -1) controls.classList.add("slideshow__controls--dark");
            else controls.classList.remove("slideshow__controls--dark");

            setInterval(() => {
                count += 1;
                if (count > max) count = 0;

                if (count === 0) {
                    list.innerHTML = listTemplate;
                    list = el.querySelector(".slideshow__list");
                    item = list.querySelectorAll(".slideshow__item");
                    controls = el.querySelector(".slideshow__controls");
                    controlsItem = el.querySelectorAll(".slideshow__controlsItem");
                    max = item.length - 1;

                    func.control(controls, controlsItem, item);
                }

                controlsItem.forEach(cItem => {
                    cItem.classList.remove("slideshow__controlsItem--active");
                });

                controlsItem[count].classList.add("slideshow__controlsItem--active");
                item[count].classList.add("slideshow__item--active");

                if (Array.prototype.slice.call(item[count].classList).indexOf("slideshow__item--dark") !== -1) controls.classList.add("slideshow__controls--dark");
                else controls.classList.remove("slideshow__controls--dark");

                setTimeout(() => {
                    item.forEach((sItem, key) => {
                        if (key !== count) sItem.classList.remove("slideshow__item--active");
                    });
                }, 500);
            }, 7e3);

            func.control(controls, controlsItem, item);
        });
    }
};

export default func;
